import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { colors, font } from '../consts/style';
import mq from '../style/mq';

import DrillWithLantern from '../images/drill-with-lantern.svg';
import ExploreWithLantern from '../images/explore-with-lantern.svg';
import { DEMO, PARTIAL } from '../consts/endpoints';
import SignUpForm from './SignUpForm';

const blueGradient =
  'transparent linear-gradient(126deg, #65C9F4 0%, #65C9F4 50%, #65C9F440 100%) 0% 0% no-repeat padding-box;';
const yellowGradient =
  'transparent linear-gradient(126deg, #FFC600 0%, #FFC600 50%, #FFC6002E 100%) 0% 0% no-repeat padding-box;';

const Wrapper = styled.div`
  margin-top: 6rem;
`;

const StepWrapper = styled.div`
  background: ${colors.dark};
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity .3s ease;

  ${props =>
    props.loading &&
    `
    opacity: .6;
    pointer-events: none;
    user-select: none;
  `}

  .step-inner-left {
    width: 38rem;
    background: ${props => props.gradient};
    flex-shrink: 0;
    padding: 2rem;
    color: ${colors.dark};
    min-height: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      ${font.h2}
      max-width: 24rem;
    }

    .step-icon-cover {
      display: flex;
      justify-content: center;
    }
  }
  .step-inner-right {
    width: 100%;
    padding: 1.6rem;
    display: flex;

    &.step-1 {
      justify-content: space-between;

      @media screen and (max-width: 420px) {
        flex-wrap: wrap;
      }
    }

    #form-time {
      position: relative;
      &:after {
        content: "PST";
        transform: translateX(-100%);
        user-select: none;
        padding: 0;
      }
    }

    .for-drilling,
    .for-exploration {
      width: 49%;
      background: transparent;
      ${font.h3}

      @media screen and (max-width: 420px) {
        width: 100%;
        margin-bottom: 1.2rem;
      }

      img {
        margin-bottom: 1.6rem;
        margin-top: 1.6rem;
      }
    }

    .for-drilling {
      border: 0.6rem solid ${colors.teal};
      color: ${colors.teal};
    }
    .for-exploration {
      border: 0.6rem solid ${colors.yellow};
      color: ${colors.yellow};
    }

    .form-wrap {
      padding: 0 1.6rem;
      width: 100%;
      .mode-toggles {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        
        button {
          background: transparent;
          display: flex;
          align-items: center;
          margin-right: 1.2rem;
          border: none;
          ${font.p};
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 1.2rem;

          &.active {
            .circle {
              position: relative;
              &:after {
                content: "";
                height: .8rem;
                width: .8rem;
                position: absolute;
                border-radius: 10rem;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          .circle {
            height: 1.4rem;
            width: 1.4rem;
            border-radius: 5rem;
            background: rgba(255,255,255,.25);
            margin-right: .8rem;
          }

          &.blue {
            color: ${colors.teal};
            .circle {
              &:after {
                background: ${colors.teal};
              }
            }
          }
          &.yellow {
            color: ${colors.yellow};
            .circle {
              &:after {
                background: ${colors.yellow};
              }
            }
          }
        }
      }
      .heading {
        ${font.h4}
        color: ${props => props.themeColor};
      }

      .copy {
        ${font.p}
        color: white;
      }
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;


      .input-wrap {
        width: 100%;
        margin-bottom: 2.4rem;
        &.date-time-wrap {
          display: flex;
          flex-wrap: wrap;

          .date-time {
            display: flex;
            align-items: center;
            margin-bottom: 1.2rem;
            
            input {
              margin-left: .8rem;
              max-width: 20rem;
              ${font.h4}
            }
          }
        }
        &.half {
          width: 49%;
        }
        input::-webkit-calendar-picker-indicator {
          background: none;
        }
        input[type="time"], input[type="date"] {
          border: none;
        }
        input {
          width: 100%;
          color: white;
          background: transparent;
          border: none;
          border-bottom: 0.2rem solid ${props => props.themeColor};
 
        }
        label {
          ${font.h4}
          font-size: 2rem;
          color: ${props => props.themeColor};
        }
      }

      .button-wrap {
        width: 100%;
        text-align: right;

        .button-submit {
          background: ${props => props.themeColor};
        }

        .button-back {
          background: ${colors.greyLight};
        }

        button {
          ${font.button}
          border-radius: 12rem;
          padding: 0.6rem 2.4rem 0.4rem;
          &:first-child {
            margin-right: 1.2rem;
          }
        }
      }
    }
  }

  ${mq.tablet`
    flex-wrap: wrap;
    .step-inner-right {
      padding: 4rem 1.2rem;
    }
    .step-inner-left, .step-inner-right {
      width: 100%;
      form {
        div.half {
          width: 100% !important;
        }
      }
    }
    .step-inner-left {
      .title, .description {
        width: 100%;
        max-width: unset;
        text-align: center;
      }
    }
  `}
`;

export default function SignUpSteps({ adminEmail }) {
  const INITIAL_FORM_STATE = {
    mode: 'drilling',
    givenName: '',
    familyName: '',
    company: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  const INITIAL_DEMO_DATA = {
    demoDate: moment()
      .add(1, 'days')
      .format('YYYY-MM-DD'),
    demoTime: '10:00',
    demoTimeZone: 'PST',
  };
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [skippedDemo, setSkippedDemo] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [demoData, setDemoData] = useState(INITIAL_DEMO_DATA);

  const gradient = formData.mode === 'drilling' ? blueGradient : yellowGradient;

  const themeColor = formData.mode === 'drilling' ? colors.teal : colors.yellow;
  const stepIcon =
    formData.mode === 'drilling' ? DrillWithLantern : ExploreWithLantern;

  function onChange(key, val) {
    setFormData({
      ...formData,
      [key]: val,
    });
  }
  function onDemoChange(key, val) {
    setDemoData({
      ...demoData,
      [key]: val,
    });
  }

  function onClickDrilling() {
    onChange('mode', 'drilling');
  }

  function onClickExplore() {
    onChange('mode', 'exploration');
  }

  function onClickBack() {
    setStep(step - 1);
  }

  function onSubmitStep1(e) {
    e.preventDefault();
    const table = 'Step 1',
      fields = {
        Company: formData.company,
        First: formData.givenName,
        Last: formData.familyName,
        Type: formData.mode,
      },
      body = JSON.stringify({
        table,
        fields,
      });

    fetch(PARTIAL, {
      method: 'post',
      body,
    });
    setStep(step + 1);
  }

  function onSubmitStep2(e) {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      return toast('Passwords do not match', {
        type: 'warning',
        position: 'bottom-center',
      });
    }
    const table = 'Step 2',
      fields = {
        Email: formData.email,
        Name: formData.givenName + ' ' + formData.familyName,
      },
      body = JSON.stringify({
        table,
        fields,
      });
    fetch(PARTIAL, {
      method: 'post',
      body,
    });
    setStep(step + 1);
  }

  function onSubmitStep3(e) {
    e.preventDefault();
    if (loading) return;

    const body = JSON.stringify({
      ...demoData,
      name: `${formData.givenName} ${formData.familyName}`,
      email: formData.email,
      company: formData.company,
      adminEmail,
    });

    setLoading(true);

    fetch(DEMO, {
      method: 'post',
      body,
    })
      .then(res => res.json())
      .then(() => {
        setStep(step + 1);
        setSkippedDemo(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setSkippedDemo(false);
        return toast('Something went wrong!', {
          type: 'warning',
          position: 'bottom-center',
        });
      });
  }

  function onSkipStep3() {
    setStep(step + 1);
    setSkippedDemo(true);
  }

  const isDrilling = formData.mode === 'drilling';

  return (
    <Wrapper>
      {step === 1 && (
        <StepWrapper gradient={gradient} themeColor={themeColor}>
          <div className="step-inner-left">
            <p className="title">Get Started For Free</p>
            <p className="description">
              Create an account to set up a contract today or explore our
              powerful features with sample data.
            </p>
          </div>
          <div className="step-inner-right">
            <div className="form-wrap">
              <div className="mode-toggles">
                <button
                  className={`blue${isDrilling ? ' active' : ''}`}
                  onClick={onClickDrilling}
                >
                  <div className="circle"></div>
                  <span>I&apos;m a drilling company</span>
                </button>
                <button
                  className={`yellow${!isDrilling ? ' active' : ''}`}
                  onClick={onClickExplore}
                >
                  <div className="circle"></div>
                  <span>I&apos;m an exploration company</span>
                </button>
              </div>
              <form
                id="sign-up-step-1"
                name="sign-up-step-1"
                autoComplete="off"
                onSubmit={onSubmitStep1}
              >
                <div className="input-wrap half">
                  <label htmlFor="form-first">First Name</label>
                  <input
                    id="form-first"
                    type="text"
                    onChange={e => onChange('givenName', e.target.value)}
                    value={formData.givenName}
                    required
                  />
                </div>
                <div className="input-wrap half">
                  <label htmlFor="form-last">Last Name</label>
                  <input
                    id="form-last"
                    type="text"
                    onChange={e => onChange('familyName', e.target.value)}
                    value={formData.familyName}
                    required
                  />
                </div>
                <div className="input-wrap">
                  <label htmlFor="form-company">Company</label>
                  <input
                    id="form-company"
                    type="text"
                    onChange={e => onChange('company', e.target.value)}
                    value={formData.company}
                    required
                  />
                </div>
                <div className="button-wrap">
                  <button type="submit" className="button-submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </StepWrapper>
      )}

      {step === 2 && (
        <StepWrapper gradient={gradient} themeColor={themeColor}>
          <div className="step-inner-left">
            <div className="step-icon-cover">
              <img src={stepIcon} alt="Step Icon" />
            </div>
          </div>
          <div className="step-inner-right">
            <div className="form-wrap">
              <form
                id="sign-up-step-2"
                name="sign-up-step-2"
                autoComplete="off"
                onSubmit={onSubmitStep2}
              >
                <div className="input-wrap">
                  <label htmlFor="form-email">Email</label>
                  <input
                    id="form-email"
                    type="email"
                    onChange={e => onChange('email', e.target.value)}
                    value={formData.email}
                    required
                  />
                </div>
                <div className="input-wrap half">
                  <label htmlFor="form-password">Password</label>
                  <input
                    id="form-password"
                    type="password"
                    onChange={e => onChange('password', e.target.value)}
                    value={formData.password}
                    minLength={8}
                    required
                  />
                </div>
                <div className="input-wrap half">
                  <label htmlFor="form-confirm">Confirm Password</label>
                  <input
                    id="form-confirm"
                    type="password"
                    onChange={e => onChange('confirmPassword', e.target.value)}
                    minLength={8}
                    value={formData.confirmPassword}
                    required
                  />
                </div>
                <div className="button-wrap">
                  <button
                    type="button"
                    className="button-back"
                    onClick={onClickBack}
                  >
                    Back
                  </button>
                  <button type="submit" className="button-submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </StepWrapper>
      )}
      {step === 3 && (
        <StepWrapper
          gradient={gradient}
          themeColor={themeColor}
          loading={loading}
        >
          <div className="step-inner-left">
            <div className="step-icon-cover">
              <img src={stepIcon} alt="Step Icon" />
            </div>
          </div>
          <div className="step-inner-right">
            <div className="form-wrap">
              <form
                id="sign-up-demo"
                name="sign-up-demo"
                autoComplete="off"
                onSubmit={onSubmitStep3}
              >
                <div className="input-wrap">
                  <p className="heading">Thank you for signing up</p>
                  <p className="copy">
                    A member of our team will be in touch to organize a free
                    tour of the software and address any initial questions.
                    Please let us know when you are available.
                  </p>
                </div>
                <div className="input-wrap date-time-wrap">
                  <div className="date-time">
                    <label htmlFor="form-date">
                      <CalendarIcon type="button" fill={themeColor} />
                    </label>
                    <input
                      type="date"
                      id="form-date"
                      value={demoData.demoDate}
                      required
                      onChange={e => onDemoChange('demoDate', e.target.value)}
                    />
                  </div>
                  <div className="date-time">
                    <label htmlFor="form-time">
                      <ClockIcon type="button" fill={themeColor} />
                    </label>
                    <input
                      type="time"
                      id="form-time"
                      value={demoData.demoTime}
                      required
                      onChange={e => onDemoChange('demoTime', e.target.value)}
                    />
                  </div>
                </div>
                <div className="button-wrap">
                  <button
                    type="button"
                    className="button-back"
                    onClick={onSkipStep3}
                  >
                    Skip
                  </button>
                  <button type="submit" className="button-submit">
                    Book
                  </button>
                </div>
              </form>
            </div>
          </div>
        </StepWrapper>
      )}
      {step === 4 && (
        <StepWrapper gradient={gradient} themeColor={themeColor}>
          <div className="step-inner-left">
            <div className="step-icon-cover">
              <img src={stepIcon} alt="Step Icon" />
            </div>
          </div>
          <div className="step-inner-right">
            <div className="form-wrap">
              <div className="input-wrap">
                <p className="heading">Time to get started!</p>
                {skippedDemo ? (
                  <p className="copy">
                    Please log in and follow the link below to begin your free
                    trial.
                  </p>
                ) : (
                    <p className="copy">
                      Your request has been submitted and we will be in touch
                      shortly. Please log in and follow the link below to begin
                      your free trial.
                    </p>
                  )}
              </div>
              <SignUpForm formData={formData} themeColor={themeColor} />
            </div>
          </div>
        </StepWrapper>
      )}
    </Wrapper>
  );
}

SignUpSteps.propTypes = {
  adminEmail: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/prop-types
const CalendarIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.113"
      height="40"
      viewBox="0 0 39.113 40"
    >
      <g
        id="calendar_1_"
        data-name="calendar (1)"
        transform="translate(-5.411)"
      >
        <g id="Group_3213" data-name="Group 3213" transform="translate(5.411)">
          <path
            id="Path_4537"
            data-name="Path 4537"
            d="M122.019,259.149a.907.907,0,0,0-.908-.907h-3.168a.907.907,0,0,0-.907.907v3.168a.908.908,0,0,0,.907.908h3.168a.908.908,0,0,0,.908-.908v-3.168Z"
            transform="translate(-107.888 -237.081)"
            fill={fill}
          />
          <path
            id="Path_4538"
            data-name="Path 4538"
            d="M218.653,259.149a.907.907,0,0,0-.907-.907h-3.169a.907.907,0,0,0-.907.907v3.168a.908.908,0,0,0,.907.908h3.169a.907.907,0,0,0,.907-.908Z"
            transform="translate(-196.605 -237.081)"
            fill={fill}
          />
          <path
            id="Path_4539"
            data-name="Path 4539"
            d="M315.282,259.149a.907.907,0,0,0-.907-.907h-3.168a.907.907,0,0,0-.908.907v3.168a.908.908,0,0,0,.908.908h3.168a.908.908,0,0,0,.907-.908Z"
            transform="translate(-285.315 -237.081)"
            fill={fill}
          />
          <path
            id="Path_4540"
            data-name="Path 4540"
            d="M122.019,355.783a.907.907,0,0,0-.908-.908h-3.168a.907.907,0,0,0-.907.908v3.167a.907.907,0,0,0,.907.907h3.168a.908.908,0,0,0,.908-.907v-3.167Z"
            transform="translate(-107.888 -325.796)"
            fill={fill}
          />
          <path
            id="Path_4541"
            data-name="Path 4541"
            d="M218.653,355.783a.907.907,0,0,0-.907-.908h-3.169a.907.907,0,0,0-.907.908v3.167a.907.907,0,0,0,.907.907h3.169a.907.907,0,0,0,.907-.907Z"
            transform="translate(-196.605 -325.796)"
            fill={fill}
          />
          <path
            id="Path_4542"
            data-name="Path 4542"
            d="M315.282,355.783a.907.907,0,0,0-.907-.908h-3.169a.907.907,0,0,0-.908.908v3.167a.907.907,0,0,0,.908.907h3.169a.907.907,0,0,0,.907-.907v-3.167Z"
            transform="translate(-285.315 -325.796)"
            fill={fill}
          />
          <path
            id="Path_4543"
            data-name="Path 4543"
            d="M41.043,54.159V59a3.952,3.952,0,0,1-3.961,3.949h-2.5A3.972,3.972,0,0,1,30.6,59V54.142H19.337V59a3.972,3.972,0,0,1-3.984,3.949h-2.5A3.952,3.952,0,0,1,8.893,59V54.159a3.6,3.6,0,0,0-3.482,3.583V86.1A3.6,3.6,0,0,0,9,89.706H40.939A3.607,3.607,0,0,0,44.524,86.1V57.742A3.6,3.6,0,0,0,41.043,54.159ZM39.882,84.333a1.55,1.55,0,0,1-1.55,1.55h-26.8a1.55,1.55,0,0,1-1.55-1.55V69.686a1.55,1.55,0,0,1,1.55-1.55h26.8a1.55,1.55,0,0,1,1.55,1.55V84.333Z"
            transform="translate(-5.411 -49.706)"
            fill={fill}
          />
          <path
            id="Path_4544"
            data-name="Path 4544"
            d="M80.908,10.648H83.38A1.358,1.358,0,0,0,84.738,9.29V1.358A1.358,1.358,0,0,0,83.38,0H80.908A1.358,1.358,0,0,0,79.55,1.358V9.29A1.358,1.358,0,0,0,80.908,10.648Z"
            transform="translate(-73.475)"
            fill={fill}
          />
          <path
            id="Path_4545"
            data-name="Path 4545"
            d="M345.816,10.648h2.471a1.358,1.358,0,0,0,1.358-1.358V1.358A1.358,1.358,0,0,0,348.288,0h-2.471a1.358,1.358,0,0,0-1.358,1.358V9.29A1.358,1.358,0,0,0,345.816,10.648Z"
            transform="translate(-316.676)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};
// eslint-disable-next-line react/prop-types
const ClockIcon = ({ fill }) => {
  return (
    <svg
      id="clock-circular-outline"
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
    >
      <g id="Group_3214" data-name="Group 3214">
        <path
          id="Path_4546"
          data-name="Path 4546"
          d="M19.5,0A19.5,19.5,0,1,0,39,19.5,19.522,19.522,0,0,0,19.5,0Zm0,34.738A15.238,15.238,0,1,1,34.738,19.5,15.255,15.255,0,0,1,19.5,34.738Z"
          fill={fill}
        />
        <path
          id="Path_4547"
          data-name="Path 4547"
          d="M260.093,149.08v-8.173a1.652,1.652,0,1,0-3.3,0v8.7c0,.026.006.05.008.076a1.642,1.642,0,0,0,.476,1.248l6.153,6.152a1.652,1.652,0,1,0,2.336-2.336Z"
          transform="translate(-238.905 -129.557)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
