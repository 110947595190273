import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { font } from '../consts/style';

const Wrapper = styled.div`
  margin-top: 6rem;
  label,
  input {
    color: white;
    display: none;
  }
  button {
    ${font.button};
    background: ${props => props.themeColor};
    width: 16rem;
    float: right;
  }
  form {
    display: flex;
    flex-direction: column;
  }
`;

SignUpForm.propTypes = {
  themeColor: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
};

export default function SignUpForm({
  themeColor,
  formData: {
    mode,
    givenName,
    familyName,
    company,
    email,
    password,
    confirmPassword,
  },
}) {
  return (
    <Wrapper themeColor={themeColor}>
      <form
        id="sign-up-form"
        name="sign-up-form"
        data-remote="1"
        action="https://we.drillwithlantern.com/signups"
        acceptCharset="UTF-8"
        method="post"
        _lpchecked="1"
      >
        <input type="hidden" name="utf8" value="✓" />
        <input
          name="signup[type]"
          id="signup_type"
          type="hidden"
          value={mode}
        />
        <input
          name="signup[account][tou_attested]"
          id="signup_account"
          type="hidden"
          value="from_marketing"
        />
        <input type="hidden" name="signup[step]" value="1" />
        <label htmlFor="signup_person_given_name">Given Name</label>
        <input
          required="required"
          data-target="autofocus.autofocus"
          name="signup[person][given_name]"
          id="signup_person_given_name"
          type="text"
          value={givenName}
        />{' '}
        <label htmlFor="signup_person_family_name">Family Name</label>
        <input
          required="required"
          name="signup[person][family_name]"
          id="signup_person_family_name"
          type="text"
          value={familyName}
        />{' '}
        <label htmlFor="signup_account_name">Organization Name</label>
        <input
          required="required"
          name="signup[account][name]"
          id="signup_account_name"
          type="text"
          value={company}
        />
        <label htmlFor="signup_user_email">Email</label>
        <input
          required="required"
          name="signup[user][email]"
          id="signup_user_email"
          type="email"
          value={email}
        />{' '}
        <label htmlFor="signup_user_password">Password</label>
        <input
          required="required"
          name="signup[user][password]"
          id="signup_user_password"
          type="password"
          value={password}
        />{' '}
        <label htmlFor="signup_user_password_confirmation">
          Password Confirmation
        </label>{' '}
        <input
          required="required"
          name="signup[user][password_confirmation]"
          id="signup_user_password_confirmation"
          type="password"
          value={confirmPassword}
        />{' '}
        <input
          type="checkbox"
          name="signup[account][tou_attested]"
          id="signup_account_tou_attested"
          value="1"
        />{' '}
        <label htmlFor="signup_account_tou_attested">
          I have read and agree to the{' '}
          <a
            href="https://staging.drillwithlantern.com/pages/terms-of-use"
            target="_tou"
          >
            Terms of Use
          </a>
          .
        </label>{' '}
        <div className="button-wrap">
          <button name="button" type="submit" value="next">
            Get Started
          </button>
        </div>
      </form>
    </Wrapper>
  );
}
