import React from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import PropTypes from 'prop-types';

import { Container, Inner } from './Elements';
import { font, colors } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  margin: 8rem 0;
  ${Inner} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .block-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

const Heading = styled.p`
  ${font.heading};
  color: white;
  margin-bottom: 1.2rem;
  width: 100%;
`;

const Description = styled.p`
  color: white;
  width: 60rem;
  max-width: 100%;
  margin-bottom: 4rem;
`;

const Block = styled.div`
  border-top: 1rem solid ${props => props.color};
  border-left: 1rem solid ${props => props.color};
  width: 24%;
  margin-right: 1.3333%;
  text-align: center;
  position: relative;
  color: white;

  .quantity {
    color: ${colors.blue};
    background: ${props => props.color};
    padding: 1.2rem;
  }

  .details {
    padding: 10rem 1.2rem;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  h3 {
    ${font.h3};
    margin: 0;
  }
  h2 {
    ${font.h4};
    text-transform: unset;
    margin-bottom: 2.4rem;
  }
  p {
    ${font.p};
  }
  ${mq.tabletSmall`
    width: 100%;
    margin-right: 0;
    margin-bottom: 2.4rem;

    .details {
      padding: 6rem 1.2rem;
    }
  `}
`;

const Gradient1 = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1rem;
  width: 100%;
  z-index: 5;
  background: linear-gradient(
    90deg,
    ${props => props.highlightColor} 0%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0)
  );
`;
const Gradient2 = styled(Gradient1)`
  height: 100%;
  width: 1rem;
  background: linear-gradient(
    180deg,
    ${props => props.highlightColor} 20%,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0)
  );
`;

const colorArr = [colors.teal, colors.yellow, colors.orange, colors.pink];

export default function PricingAlt({ pricing, title, description }) {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Heading>{title}</Heading>
          <Description>{description}</Description>
          <div className="block-wrapper">
            {map(pricing, (p, i) => {
              return (
                <Block key={p.id} color={colorArr[i]}>
                  <div className="quantity">
                    <h3>{p.quantity}</h3>
                  </div>
                  <div className="details">
                    <h2>{p.cost}</h2>
                    <div dangerouslySetInnerHTML={{ __html: p.body }} />
                  </div>
                  <Gradient1 highlightColor={colorArr[i]} />
                  <Gradient2 highlightColor={colorArr[i]} />
                </Block>
              );
            })}
          </div>
        </Inner>
      </Container>
    </Wrapper>
  );
}

PricingAlt.propTypes = {
  pricing: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
};
